@import "~hamburgers/dist/hamburgers.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Noto Sans TC', 'Microsoft JhengHei', '微軟正黑體', sans-serif;
}

.hamburger {
    padding: 0;
    width: 24px;
}

.hamburger-inner {
    background-color: #005BAB;
    width: 30px;
}

.hamburger-inner:before, .hamburger-inner:after {
    background-color: #005BAB;
    width: 30px;
}

.hamburger--spring.is-active .hamburger-inner:after,
.hamburger--spring.is-active .hamburger-inner:before {
    background-color: #005BAB;
    width: 30px;
}
